export enum BgmId {
    OVERWORLD = "overworld",
    INFERNO = "inferno",
    CAVE = "cave",
    RIDDLE = "riddle",
    RADIO = "radio",
    WINGS = "wings",
    ECSTASY = "ecstasy",
    AWAKE = "awake",
    SHADOWGATE = "shadowgate"
}
